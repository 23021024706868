<template>
<div>
    <VueSiteGuide v-model="guide" :config="guideConfig">
        <b-row>

            <b-col md="12">
                <div class="estadisticas">
                    <!-- <div class="v-step-0"> -->
                    <CCard>
                        <CCardHeader color="primario" text-color="white">
                            <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadisticas y accesos directos </span>
                            <!-- <b-button size="sm" variant="light" class="float-right" @click="guide = true">
                                <i class="fas fa-book fa-md"></i> Guía rápida
                            </b-button> -->
                        </CCardHeader>
                        <CCardBody>
                            <!-- {{$store.state.modules}} -->
                            <span class="h6 text-muted">BIENVENIDO/A {{usuario}} A CONTINUACIÓN SE MUESTRA EL ESTADO DEL SISTEMA:
                            </span>
                            <hr>
                            <b-row class="content-justify-center">
                                <b-col md="3" class="text-center my-2 icon-custom vr vr">
                                    <router-link v-if="checkPermissions('001-DOC-DEC','r') == 1" :to="{name: 'Cargar y descargar'}" class="router-link-none">
                                        <div class="estadisticasitem">
                                            <i style="inline-size: auto" class="ico fas fa-file-alt fa-3x pb-1"></i>
                                            <br>
                                            <span class="lg-numero">{{datosEstadisticos.documentosRegistrados}}</span>
                                            <br>
                                            <span class="text-muted">Documentos registrados</span>
                                        </div>
                                    </router-link>
                                    <router-link v-else :to="{}" class="router-link-none">
                                        <div class="estadisticasitem">
                                            <i style="inline-size: auto" class="ico fas fa-file-alt fa-3x pb-1"></i>
                                            <br>
                                            <span class="lg-numero">{{datosEstadisticos.documentosRegistrados}}</span>
                                            <br>
                                            <span class="text-muted">Documentos registrados</span>
                                        </div>
                                    </router-link>
                                </b-col>

                                <b-col md="3" class="text-center my-2 icon-custom vr">
                                    <router-link v-if="checkPermissions('002-PRO-OBJ','r') == 1" :to="{name: 'Objetivos'}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-flag fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.objetivosNoCumplidos}}</span>
                                        <br>
                                        <span class="text-muted">Objetivos no cumplidos</span>
                                    </router-link>
                                    <router-link v-else :to="{}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-flag fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.objetivosNoCumplidos}}</span>
                                        <br>
                                        <span class="text-muted">Objetivos no cumplidos</span>
                                    </router-link>
                                </b-col>
                                <b-col md="3" class="text-center my-2 icon-custom vr">
                                    <router-link v-if="checkPermissions('002-PRO-MAP','r') == 1" :to="{name: 'Mapa de procesos'}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-tachometer-alt fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.indicadoresRegistrados}}</span>
                                        <br>
                                        <span class="text-muted">Indicadores registrados</span>
                                    </router-link>
                                    <router-link v-else :to="{}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-tachometer-alt fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.indicadoresRegistrados}}</span>
                                        <br>
                                        <span class="text-muted">Indicadores registrados</span>
                                    </router-link>
                                </b-col>
                                <b-col md="3" class="text-center my-2 icon-custom ">
                                    <router-link v-if="checkPermissions('009-REC-INF','r') == 1" :to="{name: 'Infraestructura'}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-laptop fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.equiposFueraPlazo}}</span>
                                        <br>
                                        <span class="text-muted">Equipos fuera de plazo de revisión</span>
                                    </router-link>
                                    <router-link v-else :to="{}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-laptop fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.equiposFueraPlazo}}</span>
                                        <br>
                                        <span class="text-muted">Equipos fuera de plazo de revisión</span>
                                    </router-link>
                                </b-col>
                            </b-row>
                            <hr>
                            <b-row class="content-justify-center">
                                <b-col md="3" class="text-center my-2 icon-custom vr">
                                    <router-link v-if="checkPermissions('009-REC-REC','r') == 1" :to="{name: 'Recursos humanos'}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-users fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.trabajadores}}</span>
                                        <br>
                                        <span class="text-muted">Trabajadores</span>
                                    </router-link>
                                    <router-link v-else :to="{}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-users fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.trabajadores}}</span>
                                        <br>
                                        <span class="text-muted">Trabajadores</span>
                                    </router-link>
                                </b-col>
                                <b-col md="3" class="text-center my-2 icon-custom vr">
                                    <router-link v-if="checkPermissions('009-REC-PRO','r') == 1" :to="{name: 'Proveedores'}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-truck fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.proveedores}}</span>
                                        <br>
                                        <span class="text-muted">Proveedores</span>
                                    </router-link>
                                    <router-link v-else :to="{}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-truck fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.proveedores}}</span>
                                        <br>
                                        <span class="text-muted">Proveedores</span>
                                    </router-link>
                                </b-col>
                                <b-col md="3" class="text-center my-2 icon-custom vr">
                                    <router-link v-if="checkPermissions('010-MEJ-AUD','r') == 1" :to="{name: 'Auditoría interna'}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-edit fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.auditoriasInternas}}</span>
                                        <br>
                                        <span class="text-muted">Auditorías internas</span>
                                    </router-link>
                                    <router-link v-else :to="{}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-edit fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.auditoriasInternas}}</span>
                                        <br>
                                        <span class="text-muted">Auditorías internas</span>
                                    </router-link>
                                </b-col>
                                <b-col md="3" class="text-center my-2 icon-custom ">
                                    <router-link v-if="checkPermissions('011-INC-NOC','r') == 1" :to="{name: 'No Conformidades'}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-ban fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.noConformidadesSinResolver}}</span>
                                        <br>
                                        <span class="text-muted">No conformidades sin resolver</span>
                                    </router-link>
                                    <router-link v-else :to="{}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-ban fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.noConformidadesSinResolver}}</span>
                                        <br>
                                        <span class="text-muted">No conformidades sin resolver</span>
                                    </router-link>
                                </b-col>
                            </b-row>
                            <hr>
                            <b-row class="content-justify-center">
                                <b-col md="3" class="text-center my-2 icon-custom vr">
                                    <router-link v-if="checkPermissions('011-INC-INC','r') == 1" :to="{name: 'Incidencias'}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-exclamation-circle fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.incidenciasSinResolver}}</span>
                                        <br>
                                        <span class="text-muted">Incidencias sin resolver</span>
                                    </router-link>
                                    <router-link v-else :to="{}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-exclamation-circle fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.incidenciasSinResolver}}</span>
                                        <br>
                                        <span class="text-muted">Incidencias sin resolver</span>
                                    </router-link>
                                </b-col>
                                <b-col md="3" class="text-center my-2 icon-custom vr">
                                    <router-link v-if="checkPermissions('010-MEJ-SAT','r') == 1" :to="{name: 'Satisfaccion Cliente'}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-star-half-alt fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.porcentajeSatisfaccion}}</span>
                                        <br>
                                        <span class="text-muted">Satisfacción media en las encuestas</span>
                                    </router-link>
                                    <router-link v-else :to="{}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-star-half-alt fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.porcentajeSatisfaccion}}</span>
                                        <br>
                                        <span class="text-muted">Satisfacción media en las encuestas</span>
                                    </router-link>
                                </b-col>
                                <b-col md="3" class="text-center my-2 icon-custom vr">
                                    <router-link v-if="checkPermissions('012-USU-LIS','r') == 1" :to="{name: 'Lista de Usuarios'}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-users-cog fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.usuariosRegistrados}}</span>
                                        <br>
                                        <span class="text-muted">Usuarios registrados</span>
                                    </router-link>
                                    <router-link v-else :to="{}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-users-cog fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.usuariosRegistrados}}</span>
                                        <br>
                                        <span class="text-muted">Usuarios registrados</span>
                                    </router-link>
                                </b-col>
                                <b-col md="3" class="text-center my-2 icon-custom ">
                                    <router-link v-if="checkPermissions('013-TAR-CAL','r') == 1" :to="{name: 'Calendario'}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-calendar-alt fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.tareasPendientes}}</span>
                                        <br>
                                        <span class="text-muted">Tareas pendientes</span>
                                    </router-link>
                                    <router-link v-else :to="{}" class="router-link-none">
                                        <i style="inline-size: auto" class="ico fas fa-calendar-alt fa-3x pb-1"></i>
                                        <br>
                                        <span class="lg-numero">{{datosEstadisticos.tareasPendientes}}</span>
                                        <br>
                                        <span class="text-muted">Tareas pendientes</span>
                                    </router-link>
                                </b-col>
                            </b-row>
                        </CCardBody>
                    </CCard>
                </div>
            </b-col>
            <b-col md="12" v-if=" checkPermissions('013-TAR-CAL','r')==1">
                <!-- <div class="v-step-1"> -->
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-flag fa-md"></i> <span class="h5"> Tareas pendientes</span>
                    </CCardHeader>
                    <CCardBody>
                        <b-row>
                            <b-col lg="2">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="7">

                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                        <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col lg="12">
                                <b-table bordered hover show-empty mediun responsive outlined :items="listaTareasPendientes" :fields="camposTareasPendientes" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(opciones)="param">
                                        <b-button v-if=" checkPermissions('013-TAR-CAL','u')==1" size="sm" class="mr-1 mb-1" variant="dark" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarTarea(param)">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                        <b-button v-if=" checkPermissions('013-TAR-CAL','u')==1" size="sm" v-c-tooltip.hover.click="'Realizado'" variant="success" @click="actualizarEstadoTarea(param)" class=" mr-1 mb-1">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-check my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                        <b-button v-if=" checkPermissions('013-TAR-CAL','d')==1" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarTarea(param)" variant="danger">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </span>
                                        </b-button>

                                    </template>
                                    <template v-slot:cell(fechaInicio)="data">
                                        <span v-if="data.item.diaCompleto">{{data.item.inicio}}</span>
                                        <span v-else-if="!data.item.diaCompleto">{{data.item.inicio.substr(0,10)}} {{data.item.inicio.split("T")[1].split("-")[0]}}</span>
                                    </template>
                                    <template v-slot:cell(prioridad)="data">
                                        <h5>
                                            <b-badge v-if="data.value == 1" class="text-white" style="background: #b0b0b0;">Baja</b-badge>
                                            <b-badge v-else-if="data.value == 2" class="text-white" style="background: #5e96ea;">Normal</b-badge>
                                            <b-badge v-else-if="data.value == 3" class="text-white" style="background: #ec5b58;">Alta</b-badge>
                                        </h5>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </CCardBody>
                    <CCardFooter>
                        <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                    </CCardfooter>
                </CCard>
            </b-col>

        </b-row>
    </VueSiteGuide>

    <CModal :show.sync="modalActualizarTarea" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>

            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar tarea</span></h6>

            <CButtonClose @click="modalActualizarTarea = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarTarea)">
                <b-row>
                    <b-col lg="6">
                        <validation-provider name="tarea" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tarea:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre de tarea" v-model="datosActualizarTarea.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="empresa" v-slot="validationContext">
                            <b-form-group label="Empresa:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la empresa" v-model="datosActualizarTarea.empresa"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="localidad" v-slot="validationContext">
                            <b-form-group label="Localidad:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la localidad" v-model="datosActualizarTarea.localidad"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="contácto" v-slot="validationContext">
                            <b-form-group label="Contácto:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el contácto" v-model="datosActualizarTarea.contacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="email" :rules="{email:true}" v-slot="validationContext">
                            <b-form-group label="Email:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el email" v-model="datosActualizarTarea.email"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="teléfono" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosActualizarTarea.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="fecha de inicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de inicio:" class="mb-2">
                                <b-form-input size="md" type="date" :max="datosActualizarTarea.fechaFin" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de inicio" v-model="datosActualizarTarea.fechaInicio"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="fecha de fin" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de fin:" class="mb-2">
                                <b-form-input size="md" type="date" :min="datosActualizarTarea.fechaInicio" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha de fin" v-model="datosActualizarTarea.fechaFin"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <!-- <validation-provider name="dia completo" :rules="{required: true}" v-slot="validationContext"> -->
                        <b-form-group label="Dia completo:" class="my-2">
                            <b-form-radio-group small plain :disabled="disabledRG" v-model="datosActualizarTarea.diaCompleto" :options="radioDiaCompleto"></b-form-radio-group>
                            <!-- <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
                        </b-form-group>
                        <!-- </validation-provider> -->
                    </b-col>
                    <b-col lg="4" v-if="!datosActualizarTarea.diaCompleto">
                        <validation-provider name="hora de inicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora de inicio:" class="mb-2">
                                <!-- <b-form-timepicker v-model="datosActualizarTarea.horaFin" locale="es" :state="getValidationState(validationContext)" placeholder="Ingrese la hora de fin"></b-form-timepicker> -->
                                <b-form-input size="md" type="time" @change="validarTimes()" v-model="datosActualizarTarea.horaInicio" :state="getValidationState(validationContext)" placeholder="00:00"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4" v-if="!datosActualizarTarea.diaCompleto">
                        <validation-provider name="hora de fin" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora de fin:" class="mb-2">
                                <b-form-input size="md" type="time" @change="validarTimes()" :state="getValidationState(validationContext)" placeholder="00:00" v-model="datosActualizarTarea.horaFin"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col :lg="!datosActualizarTarea.diaCompleto ? '4' : '6'">
                        <validation-provider name="estado" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Estado:" class="mb-2">
                                <b-form-select :state="getValidationState(validationContext)" :options="comboEstado" value-field="idEstado" text-field="descripcion" v-model="datosActualizarTarea.subEstado" size="md">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col :lg="!datosActualizarTarea.diaCompleto ? '4' : '6'">
                        <validation-provider name="prioridad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Prioridad:" class="mb-2">
                                <b-form-select :state="getValidationState(validationContext)" :options="comboPrioridad" value-field="idPrioridad" text-field="descripcion" v-model="datosActualizarTarea.prioridad" size="md">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col :lg="!datosActualizarTarea.diaCompleto ? '8' : '12'">
                        <validation-provider name="observaciones" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarTarea.observaciones" :state="getValidationState(validationContext)" placeholder="Ingrese la observacion" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12" class="text-right my-2">
                        <b-button size="md" :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarTarea= false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import VueSiteGuide from 'vue-site-guide'

import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {
        VueSiteGuide,
    },
    data() {
        return {
            disabled: false,
            disabledRG: false,
            modalActualizarTarea: false,
            guide: false,
            guideConfig: {
                steps: [{
                        selector: '.estadisticas',
                        title: 'Panel de Estadísticas',
                        message: 'En el lado izquierdo de su pantalla encontrará el Panel de Estadísticas, que le servirá para ver de forma rápida y visual el estado del sistema.',

                    },
                    {
                        selector: '.estadisticasitem',
                        title: 'Documentos registrados',
                        message: 'En el lado izquierdo de su pantalla encontrará el Panel de Estadísticas, que le servirá para ver de forma rápida y visual el estado del sistema.',
                    }

                ],
                prevButtonText: 'Atras',
                nextButtonText: 'Siguiente',
                skipButtonText: 'Finalizar',
                skipButtonClass: 'text-dark',
                // nextButtonStyle: {
                //     background: '#00000 !important'
                // }
            },
            usuario: "",

            listaTareasPendientes: [],
            camposTareasPendientes: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "empresa",
                    label: "Empresa",
                    class: "text-center",
                },
                {
                    key: "localidad",
                    label: "Localidad",
                    class: "text-center",
                },
                {
                    key: "contacto",
                    label: "Contacto",
                    class: "text-center",
                },
                {
                    key: "telefono",
                    label: "Teléfono",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Tarea",
                    class: "text-center",
                },
                {
                    key: "fechaInicio",
                    label: "Fecha de inicio",
                    class: "text-center",
                },

                {
                    key: "prioridad",
                    label: "Prioridad",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],

            datosTarea: {
                idTarea: '',
                nombre: '',
                empresa: '',
                correo: '',
                telefono: '',
                fechaInicio: '',
                fechaFin: '',
                diaCompleto: true,
                horaInicio: '',
                horaFin: '',
                idEstado: null,
                idPrioridad: null,
                observacion: '',
                idCliente: '',

            },

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            filter: null,
            filterOn: [],

            paginaOpciones: [10, 25, 50, {
                value: 100,
                text: "100 "
            }],

            comboEstado: [{
                    idEstado: 1,
                    descripcion: 'Pendiente',
                },
                {
                    idEstado: 2,
                    descripcion: 'Realizada',
                },
            ],
            comboPrioridad: [{
                    idPrioridad: '1',
                    descripcion: 'Baja'
                },
                {
                    idPrioridad: '2',
                    descripcion: 'Media'
                }, {
                    idPrioridad: '3',
                    descripcion: 'Alta'
                }
            ],

            radioDiaCompleto: [{
                    text: 'SI',
                    value: true
                },
                {
                    text: 'NO',
                    value: false
                }
            ],
            modalModificarTarea: false,
            datosSession: {
                idCliente: ''
            },
            datosActualizarTarea: {
                idTarea: '',
                idCliente: '',
                nombre: '',
                empresa: '',
                localidad: '',
                contacto: '',
                email: '',
                telefono: '',
                gtm: this.getGMT(),
                diaCompleto: true,
                subEstado: null,
                prioridad: null,
                observaciones: '',
                horaInicio: '',
                horaFin: '',
                fechaInicio: '',
                fechaFin: '',
            },

            datosEstadisticos: {
                documentosRegistrados: 0,
                objetivosNoCumplidos: 0,
                indicadoresRegistrados: 0,
                equiposFueraPlazo: 0,
                trabajadores: 0,
                proveedores: 0,
                auditoriasInternas: 0,
                noConformidadesSinResolver: 0,
                incidenciasSinResolver: 0,
                usuariosRegistrados: 0,
                tareasPendientes: 0,
                porcentajeSatisfaccion: '0%'
            }
        }

    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getGMT() {
            let gmt = moment().format('Z').substr(1, 10)
            return gmt;
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },

        guiaRapida() {
            this.$tours['myTour'].start()

        },
        obtenerDatosEstadisticos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-datos-estadisticos", {
                    params: {
                        idCliente: me.datosSession.idCliente
                    }
                })
                .then(function (response) {
                    me.datosEstadisticos.documentosRegistrados  = response.data[0][0].documentosRegistrados;
                    me.datosEstadisticos.objetivosNoCumplidos = response.data[1][0].objetivosNoCumplidos;
                    me.datosEstadisticos.indicadoresRegistrados = response.data[2][0].indicadoresRegistrados;
                    me.datosEstadisticos.equiposFueraPlazo = response.data[3][0].equiposFueraPlazo;
                    me.datosEstadisticos.trabajadores = response.data[4][0].trabajadores;
                    me.datosEstadisticos.proveedores = response.data[5][0].proveedores;
                    me.datosEstadisticos.auditoriasInternas = response.data[6][0].auditoriasInternas;
                    me.datosEstadisticos.noConformidadesSinResolver = response.data[7][0].noConformidadesSinResolver;
                    me.datosEstadisticos.incidenciasSinResolver = response.data[8][0].incidenciasSinResolver;
                    me.datosEstadisticos.usuariosRegistrados = response.data[9][0].usuariosRegistrados;
                    me.datosEstadisticos.tareasPendientes = response.data[10][0].tareasPendientes;
                    me.datosEstadisticos.porcentajeSatisfaccion = response.data[11][0].porcentajeSatisfaccion ? response.data[11][0].porcentajeSatisfaccion : '0%';
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        listarTareas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-tareas", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaTareasPendientes = response.data.filter(x => x.subEstado == 1)
                    me.filasTotales = me.listaTareasPendientes.length;

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarEstadoTarea(param) {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/actualizar-estado-tarea", {
                        idTarea: param.item.idTarea
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarTareas();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarTarea(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la tarea?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-tarea", {
                                idTarea: param.item.idTarea
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarTareas();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        abrirModalActualizarTarea(param) {
            let me = this;
            me.datosActualizarTarea.idTarea = param.item.idTarea;
            me.datosActualizarTarea.idCliente = param.item.idCliente;
            me.datosActualizarTarea.nombre = param.item.nombre;
            me.datosActualizarTarea.empresa = param.item.empresa;
            me.datosActualizarTarea.localidad = param.item.localidad;
            me.datosActualizarTarea.contacto = param.item.contacto;
            me.datosActualizarTarea.email = param.item.email;
            me.datosActualizarTarea.telefono = param.item.telefono;
            me.datosActualizarTarea.subEstado = param.item.subEstado;
            me.datosActualizarTarea.prioridad = param.item.prioridad;
            me.datosActualizarTarea.observaciones = param.item.observaciones;

            me.datosActualizarTarea.diaCompleto = param.item.diaCompleto == 1 ? true : false;

            if (param.item.diaCompleto == 1) {
                me.datosActualizarTarea.fechaInicio = param.item.inicio;
                me.datosActualizarTarea.fechaFin = param.item.fin;
                me.datosActualizarTarea.horaInicio = '';
                me.datosActualizarTarea.horaFin = '';
            } else if (param.item.diaCompleto == 0) {
                me.datosActualizarTarea.horaInicio = param.item.inicio.split("T")[1].split("-")[0];
                me.datosActualizarTarea.horaFin = param.item.fin.split("T")[1].split("-")[0];
                me.datosActualizarTarea.fechaInicio = param.item.inicio.substr(0, 10)
                me.datosActualizarTarea.fechaFin = param.item.fin.substr(0, 10)
            }
            this.modalActualizarTarea = true;
        },
        actualizarTarea() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-tarea",
                    me.datosActualizarTarea, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalActualizarTarea = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarTareas();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });
        },
        resetModalActualizarTarea() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

        },

    },

    watch: {
        modalActualizarTarea: function (val) {
            if (val == false) {
                this.resetModalActualizarTarea();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.usuario = user.username;
            this.obtenerDatosEstadisticos();
            this.listarTareas();
        }
    }
}
</script>
